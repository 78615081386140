<template>
  <div class="form-input-box">
    <div class="form-box">
      <table class="form-input-table">
        <tr>
          <td>
            <label class="form-label">{{label}}</label>
            <i v-if="required" class="required-txt">*</i>
          </td>
          <td>
            <p class="select-txt" :class="{'select-txt-placeholder':!bindValue,'select-txt-placeholder-disable':disable}" @click="itemEvent">{{bindValue?bindValue:"请选择"}}</p>
          </td>
        </tr>
      </table>
    </div>
    <i class="select-icon"></i>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "x-form-label",
  data () {
    return {};
  },
  props: {
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    bindValue: {
      type: String,
      default: null
    }
  },
  created () { },
  methods: {
    itemEvent(){
      this.$emit("change");
    }
  },
  components: {}
};
</script>

<style scoped>
.form-input-box {
  display: flex;
  align-items: center;
  padding: 0.16rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
}
.form-box {
  flex: 1;
}
.form-input-table {
  width: 100%;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.select-icon {
  margin-left: 0.1rem;
  width: 0.07rem;
  height: 0.12rem;
  background: url('~@images/select_icon.png') no-repeat center/0.07rem 0.12rem;
}
.select-txt {
  font-size: 0.14rem;
  text-align: right;
  color: #30353d;
}
.select-txt-placeholder-disable {
  color: #737885;
}
.select-txt-placeholder {
  color: #afb2b9;
}
.required-txt {
  margin-left: 0.04rem;
  font-size: 0.14rem;
  color: #f75959;
}
</style>
