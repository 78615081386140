<template>
  <div class="app-box">
    <x-scroll-view>
      <x-form-box>
        <x-form-select v-show="hardwareType!=1&&xhrData.equipmentTypeValue!=null" :label="hardwareTypeLabel" disable :slots="EquipmentTypeEnum" :bindValue="xhrData.equipmentTypeValue"></x-form-select>
        <!-- <x-form-input v-if="hardwareType!=1&&xhrData.otherEquipmentType" label="新建设备类型" disable v-model="xhrData.otherEquipmentType"></x-form-input> -->
        <x-form-label v-if="xhrData.brandName" :label="xhrData.hardwareType==1?'牙椅品牌':'品牌'" disable :bindValue="xhrData.brandName"></x-form-label>
        <!-- <x-form-input v-if="xhrData.otherBrandName" label="新建品牌" disable v-model="xhrData.otherBrandName"></x-form-input> -->
        <x-form-input v-if="xhrData.model" label="型号" v-model="xhrData.model" disable></x-form-input>
        <template v-if="customerType==1000">
          <x-form-date v-show="xhrData.installedTime" label="安装时间" disable :defaultDate="xhrData.installedTime"></x-form-date>
          <x-form-input v-if="xhrData.installedCount" label="数量" v-model="xhrData.installedCount" disable>
            <label class="from-unit">台</label>
          </x-form-input>
        </template>
      </x-form-box>
      <i v-if="!isLeader" class="edit-icon" @click="editEvent"></i>
    </x-scroll-view>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import formDate from "@/components/formControl/formDate";
import formLabel from "@/components/formControl/formLabel";
import scrollView from "@/components/scrollView";
import { getHardware } from "@/api/customerCenter";

export default {
  data () {
    return {
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      customerType: this.$route.params.customerType,
      hardwareType: this.$route.params.hardwareType,
      hardwareTypeLabel: null,
      EquipmentTypeEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      xhrData: {}
    };
  },
  created () {
    switch (this.hardwareType) {
      case "999":
        this.hardwareTypeLabel = "其他设备类型";
        break;
      case "2":
        this.hardwareTypeLabel = "数字化设备类型";
        break;
      case "3":
        this.hardwareTypeLabel = "器械类型";
        break;
      default:
        break;
    }
    this.setEnum();
  },
  methods: {
    setEnum () {
      if (this.hardwareType != 1) {
        let configJson = null;
        const configJsonData = JSON.parse(sessionStorage.getItem("configJson"));
        switch (this.hardwareType) {
          case "999":
            configJson = configJsonData.OtherEquipmentTypeEnum;
            break;
          case "2":
            configJson = configJsonData.DigitalEquipmentTypeEnum;
            break;
          case "3":
            configJson = configJsonData.TreatmentEquipmentTypeEnum;
            break;
          default:
            break;
        }
        Object.keys(configJson).forEach(key => {
          this.EquipmentTypeEnum[0].values.push({
            code: key,
            name: configJson[key]
          });
        });
      }
      this.getData();
    },
    getData () {
      getHardware({
        hardwareId: this.$route.params.hardwareId
      }).then(xhr => {
        this.xhrData = xhr.data;
        document.title = "";
        document.title = this.xhrData.brandName;
      });
    },
    editEvent () {
      this.$router.push({
        path: `/customerCenter/hardwareInfo/edit/edit/${this.$route.params.hardwareId}/${this.hardwareType}/${this.customerType}`
      });
    }
  },
  components: {
    [formBox.name]: formBox,
    [formInput.name]: formInput,
    [formSelect.name]: formSelect,
    [formDate.name]: formDate,
    [formLabel.name]: formLabel,
    [scrollView.name]: scrollView
  }
};
</script>

<style scoped></style>
